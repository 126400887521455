<template>
  <div>
    <dashboard-page-title
      @addEvent="$router.push({ name: 'addRole' })"
      :showAddBtn="$perHelper('roles:create')"
    >
      {{ $t("roles.roles") }}
    </dashboard-page-title>
    <main-table :fields="fields" :list_url="'merchant/roles'"></main-table>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      fields: [
        // '#',
        { label: this.$t("main.name"), key: "name", class: "text-start" },
        // { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' }
        {
          label: this.$t("products.control"),
          key: "actions",
          class: "text-start",
          type: "actions",
          actions: [
            {
              text: "edit",
              icon: "ri-ball-pen-fill",
              color: "info",
              ifNavigate: true,
              routePath: "edit-roles/:id",
              showIf: () => this.$perHelper("roles:update"),
            },
            {
              text: "Delete",
              icon: "ri-delete-bin-line",
              color: "danger",
              url: "merchant/roles",
              titleHeader: this.$t("main.item"),
              question: this.$t("main.deleteConfirmation"),
              textContent: "name",
              textDeleteButton: this.$t("main.delete"),
              textCancelButton: this.$t("main.cancel"),
              showAlert: true,
              // showIf: () => this.$perHelper('roles:soft_delete')
            },
          ],
        },
      ],
    };
  },
  methods: {
    addMerchant() {
      console.log("hello");
    },
  },
};
</script>
